@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

h1 {
  text-transform: uppercase;
}

.header {
  display: flex;
}

.header h1 {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.header .logo {
  display: flex;
  align-items: center;
}

.header .logo img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #000;
}

.main-category-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.main-category-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.link-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0 10px 0;
}

.link-item img {
  display: flex;
  align-items: center;
  background-color: #000;
  width: 180px;
  height: 48px;
  object-fit: contain;
}

.link-item-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  width: 50%;
}

.link-item-name a {
  text-decoration: none;
  color: #000;
}

.link-item-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  margin-top: 20px;
}